<template>
    <div class="button-editor-container">
        <p style="line-height:1" class="mt-2 mb-1">Link Type</p>
        <a-select v-model="form.type" :options="linkType" />

        <p style="line-height:1" class="mt-2 mb-1">Text Label</p>
        <a-input v-model="form.label" />

        <template v-if="form.type === 'link'">
            <p style="line-height:1" class="mt-2 mb-1">External Link (URL)</p>
            <a-input v-model="form.value" @change="viewFormLink(form.value)" />
        </template>
        <template v-else-if="form.type === 'section'">
            <p style="line-height:1" class="mt-2 mb-1">Select a Section/Modal</p>
            <a-select v-model="form.value" :options="pageSectionsOptions" @change="viewFormLink(`#sect-${form.value}`)" />
        </template>
        <template v-else-if="form.type === 'page'">
            <p style="line-height:1" class="mt-2 mb-1">Select a Page</p>
            <a-select v-model="form.value" :options="pagesOptions" @change="viewFormLink(form.value)" />
        </template>
    </div>

</template>

<script>
export default {
    props:{
        value:{
            type:Object,
            required:true,
            default: () => {}
        },
    },
    computed:{
        linkType(){
            let types =  [{label:'External Link',value:'link'},{label:'Goto Section',value:'section'},{label:'Goto Page',value:'page'}]
            // if (!this.$store.getters.isLanding) types.push({label:'Goto Page',value:'page'})
            return types
        },
        pageSectionsOptions(){
            return this.$store.getters.pageSectionsOptions
        },
        pagesOptions(){
            return this.$store.getters.pagesOptions
        },
    },
    data(){
        return {
            watch:false,
            form:{
                type: "",
                value:'',
                label: "",
                link:""
            },
        }
    },
	methods:{
		viewFormLink(link){
			console.log('FORM LINK', link)
			this.form.link = link
		}
	},
    watch:{
        'form.type'(val){
            
            // if (val === 'section' && this.pageSectionsOptions[0]){
            //     this.form.value = this.pageSectionsOptions[0].value
			// 	this.form.link = this.pageSectionsOptions[0].value
            // } else if (val === 'link'){
            //     this.form.value = '#'
			// 	this.form.link = '#'
            // } else if (val === 'page' && this.pagesOptions[0]){
            //     this.form.value = this.pagesOptions[0].value
			// 	this.form.link = this.pagesOptions[0].value
            // }
        },
        form:{
            deep:true,
            handler(val,oldVal){
                if (!this.watch) return this.watch = true;
                return this.$emit('input', val)
            }
        }
    },
    created(){
        let value = this.value || {type:'',value:'',label:'',link:''}
		if (value.type == 'section' && this.pageSectionsOptions[0]){
			let findIndex = this.pageSectionsOptions.findIndex(x => x.value == value.value)
			if (findIndex == -1) value.value = ''
		} else if (value.type == 'page' && this.pagesOptions[0]){
			let findIndex = this.pagesOptions.findIndex(x => x.value == value.value)
			if (findIndex == -1) value.value = ''
		}

        this.form = {
            ...this.form,
            ...value
        }
		console.log('LINK EDITOR DATA', this.form)
    },
}
</script>

<style scoped lang="scss">
    .button-editor-container{
        padding:10px;
        border:1px solid #eee;
        
    }
</style>