<template>
    <div class="px-2">

        <!-- Categories -->
        <span style="font-weight: bold;">Categories</span>
        <div>
            <div v-if="portfolio && portfolio.categories && portfolio.categories.length">
                <template v-for="(cat,catI) in portfolio.categories">

                    <a-collapse expandIconPosition="right" :key="catI" class="mb-2">

                        <a-collapse-panel key="1" :header="cat.name || 'Category'" :style="'background: #f7f7f7;border-radius: 4px;;overflow: hidden;'">

                                <div class="img-alt-container mb-4">
                                

                                    <p style="line-height:1" class="mt-3 mb-1">Category Name</p>
                                    <a-input v-model="cat.name" />

                                    <p style="line-height:1" class="mt-3 mb-1">Category Header</p>
                                    <RichText v-model="cat.title" :format="false" :variable="$store.state.appData.builder.richVariables" :variables="$store.state.appData.builder.variables" />

                                    <p style="line-height:1" class="mt-3 mb-1">Category Body</p>
                                    <RichText v-model="cat.body" :format="true" :variable="$store.state.appData.builder.richVariables" :variables="$store.state.appData.builder.variables" />

                                    
                                </div>

                            <a-popconfirm
                                title="Are you sure delete this category?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="removeCategory(catI)"
                            >
                                <a href="#"  class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc; border-color:var(--danger); color:var(--danger)">
                                    <a-icon type="minus-circle" /> Remove Category
                                </a>
                            </a-popconfirm>
                            

                        </a-collapse-panel>

                    </a-collapse>
                        
                </template>
            </div>
            <div v-else class="empty-blocks p-3" style="color:#ccc; font-size:14px; text-align:center;">
                No Categories Added
            </div>
            <template v-if="portfolio && portfolio.categories && portfolio.categories.length <= 5">
                <div @click="addCategory" class="p-3 dF jC aC cursor-pointer no-selection" style="gap:1ch; border:1px solid #ccc; background:var(--light-purple); color:var(--black); font-size:14px">
                    <a-icon type="plus-circle" />Add Category
                </div>
            </template>
            <div v-else class="p-3 dF jC aC fC cursor-pointer no-selection text-center" style="border:1px solid #ccc; background:var(--light-purple); color:var(--black); font-size:14px">
                
                <div class="dF aC" style="gap:1ch;">
                    <a-icon type="stop" /> Category limit reached
                </div> 
            </div>
        </div>

        <hr />

        <!-- Portfolios -->
        <span style="font-weight: bold;">Portfolios</span>
        <div>
            <div v-if="portfolio && portfolio.portfolios && portfolio.portfolios.length">
                <template v-for="(port,portI) in portfolio.portfolios">

                    <a-collapse expandIconPosition="right" :key="portI" class="mb-2">

                        <a-collapse-panel key="1" :header="port.title || 'Portfolio'" :style="'background: #f7f7f7;border-radius: 4px;;overflow: hidden;'">

                                <div class="img-alt-container mb-4">

                                    <p style="line-height:1" class="mt-3 mb-1">Categories</p>
                                    <a-select
                                        mode="multiple"
                                        v-model="port.categories"
                                        style="width: 100%"
                                    >
                                        <a-select-option v-for="(p,pI) in portfolio.categories" :key="p+pI" :value="p.id">
                                            {{ p.name }}
                                        </a-select-option>
                                    </a-select>

                                    <div class="mt-3">
                                        <ImageBoxSelector v-model="port.img" :removable="true" />
                                    </div>

                                    <p style="line-height:1" class="mt-3 mb-1">Portfolio Label</p>
                                    <RichText v-model="port.label" :format="false" :variable="$store.state.appData.builder.richVariables" :variables="$store.state.appData.builder.variables" />

                                    <p style="line-height:1" class="mt-3 mb-1">Portfolio Title</p>
                                    <RichText v-model="port.title" :format="false" :variable="$store.state.appData.builder.richVariables" :variables="$store.state.appData.builder.variables" />
                                    
                                    <p style="line-height:1" class="mt-3 mb-1">Portfolio Body</p>
                                    <RichText v-model="port.body" :format="true" :variable="$store.state.appData.builder.richVariables" :variables="$store.state.appData.builder.variables" />

                                    <LinkEditor class="mt-3" v-model="port.link" />


                                </div>

                            <a-popconfirm
                                title="Are you sure delete this portfolio?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="removePortfolio(portI)"
                            >
                                <a href="#"  class="aC gap-2 py-1 px-2 rounded-sm hover:text-black" style="display:inline-flex; gap:1ch; border:1px solid #ccc; border-color:var(--danger); color:var(--danger)">
                                    <a-icon type="minus-circle" /> Remove Portfolio
                                </a>
                            </a-popconfirm>
                            

                        </a-collapse-panel>

                    </a-collapse>
                        
                </template>
            </div>
            <div v-else class="empty-blocks p-3" style="color:#ccc; font-size:14px; text-align:center;">
                No Portfolios Added
            </div>
            <template>
                <div @click="addPortfolio" class="p-3 dF jC aC cursor-pointer no-selection" style="gap:1ch; border:1px solid #ccc; background:var(--light-purple); color:var(--black); font-size:14px">
                    <a-icon type="plus-circle" />Add Portfolio
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import RichText from '@/components/common/RichText'
import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
import LinkEditor from '@/components/site/LinkEditor'

export default {
    components:{RichText,ImageBoxSelector,LinkEditor},
    props:{
        value:{
            type:Object,
            required:true,
            default: () => {}
        },
    },
    data(){
        return {
            portfolio:{
                categories:[
                    {
                        name:'All Projects',
                        id:'allprojects',
                        title:'To inspire &<br>be inspired',
                        body:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.'
                    }
                ],
                portfolios:[
                    {
                        title:'Bond Head',
                        id:'23456',
                        img:'https://bildhive.nyc3.digitaloceanspaces.com/noinstace/e5171c9dafbf4adbb5ff2de17068d690.png',
                        label:'Now Selling',
                        body:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.',
                        link:{
                            type:'page',
                            link:'/',
                            label:'VIEW PROJECT'
                        },
                        categories:['allprojects']
                    }
                ]
            },
            
        }
    },
    computed:{

    },
    methods:{
        addCategory(){
            let newBlock = {
                name:'New Category',
                id:Date.now(),
                title:'To inspire &<br>be inspired',
                body:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.'
            }
            if (this.limitReached){
                console.error('Limit reached for blocks')
            } else {
                console.log('else from add block ran')
                this.portfolio.categories.push(newBlock)
            }
        },
        addPortfolio(){
            let newBlock = {
                title:'Portfolio Title',
                id:Date.now(),
                img:'https://bildhive.nyc3.digitaloceanspaces.com/noinstace/e5171c9dafbf4adbb5ff2de17068d690.png',
                label:'Now Selling',
                body:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.',
                link:{
                    type:'page',
                    link:'/',
                    label:'VIEW PROJECT'
                },
                categories:[]
            }
            if (this.limitReached){
                console.error('Limit reached for blocks')
            } else {
                console.log('else from add block ran')
				if (this.portfolio.categories && this.portfolio.categories.length){
					newBlock.categories.push(this.portfolio.categories[0].id)
				}
                this.portfolio.portfolios.push(newBlock)
            }
        },
        removeCategory(i){
            this.portfolio.categories.splice(i,1)
        },
        removePortfolio(i){
            this.portfolio.portfolios.splice(i,1)
        },
    },
    watch:{
        portfolio:{
            handler(val,oldVal){
                return this.$emit('input', val)
            }
        },
        'portfolio.categories':{
            handler(val){
                let existingCats = []
                if (val && val.length){
                    val.forEach(cat => {
                        existingCats.push(cat.id)
                    })
                }
                this.portfolio.portfolios.forEach(port => {
                    if (port.categories && port.categories.length){
                        port.categories.forEach(cat => {
                            if (!existingCats.includes(cat)){
                                port.categories.splice(port.categories.indexOf(cat),1)
                            }
                        })
                    }
                })
            },
            deep:true
        },
    },
    created(){
        
    },
    mounted(){
        // if (!this.value) this.instance = this.instances[0].value
        if (this.value){
            console.log('CREATED THIS VALUE', this.value)
            this.portfolio = this.value
        }
    }
}
</script>

<style scoped lang="scss">
    .button-editor-container{
        padding:10px;
        border:1px solid #eee;
        
    }
</style>